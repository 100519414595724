
/**
 * First we will load all of this project's JavaScript dependencies
 */

/* initialise jQuery */
window.$ = window.jQuery = require('jquery');

require('bootstrap-sass');
require('./bootstrap');
